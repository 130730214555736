import React, { useEffect, useLayoutEffect, useState } from "react";
import HomeNavbar from "./components/inc/HomeNavbar";
import Home from "./components/pages/main-pages/Home";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import Chess from "./components/games/chess/Chess";
import BrowseQuiz from "./components/pages/main-pages/Browse-Quiz";
import Quiz from "./components/pages/quiz-inc/Quiz";
import Footer from "./components/inc/Footer";
import MoveToTopButton from "./components/inc/MoveToTopBtn";
import CreateQuiz from "./components/pages/admin-pages/Create-Quiz";
import AddQuestion from "./components/pages/admin-pages/Add-Question";
import Login from "./components/pages/main-pages/Login";
import "./App.css";
import Aos from "aos";
import "aos/dist/aos.css";
import { useLocation } from "react-router-dom";
import OAuth2RedirectHandler from "./components/pages/oauth/OAuth2RedirectHandler";
import PrivateRoute from "./components/pages/profile/PrivateRoute";
import LoadingIndicator from "./components/inc/LoadingIndicator";
import { ACCESS_TOKEN, USER_DETAILS, PREV_LOCATION } from './components/constants/config';
import { getCurrentUser } from "./components/common/ApiUtils";
import MissionVision from "./components/inc/MissionVision";
import TermsofService from "./components/inc/TermsofService";
import PrivacyPolicy from "./components/inc/PrivacyPolicy";
import Leaderboard from "./components/pages/quiz-inc/Leaderboard";
import Profile from "./components/pages/profile/Profile";
import Faq from "./components/inc/Faq";
import ContactUs from "./components/inc/ContactUs";
import PageNotFound from "./components/inc/PageNotFound";
import TikTacToe from "./components/games/tiktactoe/TikTacToe";
import TikTacToeScreen1 from "./components/games/tiktactoe/TikTacToeScreen1";

function App() {
  useEffect(() => {
    Aos.init();
  }, []);

  const Wrapper = ({ children }) => {
    const location = useLocation();
    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children;
  };

  // const { authenticated, currentUser, loading, handleLogout } = useAuth();
  const [authenticated, setAuthenticated] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleLogout = () => {
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(USER_DETAILS);
    localStorage.removeItem(PREV_LOCATION);
    setAuthenticated(false);
    setCurrentUser(null);
  };

  useEffect(() => {
    loadCurrentlyLoggedInUser();
  }, []);

  const loadCurrentlyLoggedInUser = () => {
    setLoading(true);
    getCurrentUser()
      .then(response => {
        setCurrentUser(response);
        setAuthenticated(true);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
      });
  };

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <Router>
      <Wrapper>
        <React.Fragment>
          <HomeNavbar authenticated={authenticated} currentUser={currentUser} onLogout={handleLogout} />
          <Routes>
            <Route path="/" element={<Home />} />

            {/* --------------------  Quiz Pages  -------------------- */}
            <Route path="/browse-quiz" element={<BrowseQuiz />} />
            <Route path="/create-quiz" element={<CreateQuiz />} />
            <Route path="/add-question" element={<AddQuestion />} />

            {/* --------------------  About Us Pages  -------------------- */}
            {/* <Route path="/about" element={<AboutUs />} /> */}

            {/* --------------------  Blog Pages  -------------------- */}
            {/* <Route path="/blog" element={<Blog />} /> */}
            {/* <Route path="/blog/:selectedBlogPost" element={<Article />}></Route> */}

            {/* --------------------  Challenges Pages  -------------------- */}
            {/* <Route path="/challenges" element={<Challenges />} /> */}

            {/* --------------------  Login  -------------------- */}
            <Route path="/login" element={<Login authenticated={authenticated} />} />
            <Route path="/oauth2/redirect" element={<OAuth2RedirectHandler />}></Route>

            <Route element={<PrivateRoute authenticated={authenticated} />}>
              {/* --------------------  Protected Routes  -------------------- */}
              <Route path="/quiz/:quizId/:selectedQuiz" element={<Quiz currentUser={currentUser ? currentUser.user : ""} />}></Route>
              <Route path="/profile" element={<Profile currentUser={currentUser} />}></Route>
            </Route>

            {/* --------------------  Games  -------------------- */}
            {/* <Route path="/games" element={<Games />}></Route> */}
            <Route path="/games/chess" element={<Chess />}></Route>
            <Route path="/games/tiktactoe" element={<TikTacToeScreen1 />}></Route>
            <Route path="/games/tiktactoe-twoplayer" element={<TikTacToe />}></Route>

            <Route path="/leaderboard" element={<Leaderboard />}></Route>
            {/* --------------------  Footer  -------------------- */}
            <Route path="/legal/mission-vision" element={<MissionVision />} />
            <Route path="/legal/terms-of-service" element={<TermsofService />} />
            <Route path="/legal/privacy-policy" element={<PrivacyPolicy />} />

            <Route path="/company/faq" element={<Faq />} />
            {/* <Route path="/company/testimonials" element={<Testimonials />} /> */}

            <Route path="/connect/contactus" element={<ContactUs authenticated={authenticated} currentUser={currentUser} />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>

          {/* --------------------  Common Utils  -------------------- */}
          <MoveToTopButton />
          <Footer />

        </React.Fragment>
      </Wrapper>
    </Router>
  );
}

export default App;
