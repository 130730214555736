import React from "react";

function TermsofService() {
    return (
        <section className="termsconditions">
            <div className="container p-0 overflow-hidden"
                style={{
                    marginTop: "80px", marginBottom: "20px",
                    boxShadow: "2px 2px 20px black", borderRadius: "20px"
                }}>
                <div className="text-center text-white broadcast p-5">
                    <h1>Terms of Service</h1>
                </div>
                <div className="p-5">
                    <p className="text-info fst-italic">Effective Date: 21 September 2023</p>

                    <h2>1. Acceptance of Terms</h2>
                    <p>
                        By accessing and using ThePuzzleFactory.co (hereinafter referred to as "the Website"), you agree to
                        comply with and be bound by these terms and conditions. If you do not agree to these terms and conditions,
                        please do not use the Website.
                    </p>
                    <h2>2. Changes to Terms</h2>
                    <p>
                        The Puzzle Factory("we" or "us") reserves the right to update, modify, or change these terms
                        and conditions at any time without notice. Changes will be effective immediately
                        upon posting on the Website. Your continued use of the Website after any such
                        changes constitutes your acceptance of the updated terms and conditions.
                    </p>
                    <h2>3. Use of the Website</h2>
                    <p>
                        3.1. You agree to use the Website for lawful purposes only and in a manner
                        consistent with all applicable laws and regulations.</p>

                    <p> 3.2. You are solely responsible for maintaining the confidentiality of your account information,
                        including your username and password. You are responsible for all activities that occur under your account.

                    </p>
                    <h2>4. User Content</h2>
                    <p>
                        4.1. By submitting content to the Website, including but not limited to quizzes,
                        comments, or other materials, you grant The Puzzle Factory a non-exclusive,
                        royalty-free, worldwide, perpetual, and irrevocable right to use, reproduce,
                        modify, adapt, publish, translate, distribute, and display such content.
                    </p>
                    <p>
                        4.2. You agree not to submit any content that is unlawful, harmful, threatening,
                        abusive, harassing, defamatory, vulgar, obscene, libelous, invasive of another's
                        privacy, or otherwise objectionable.
                    </p>
                    <h2>5. Intellectual Property</h2>
                    <p>
                        5.1. All content and materials on the Website, including text, graphics,
                        logos, and images, are the property of The Puzzle Factory or its licensors and
                        are protected by copyright, trademark, and other intellectual property laws.
                    </p>
                    <p>
                        5.2. You may not reproduce, distribute, modify, or create derivative works from
                        any content on the Website without prior written consent from The Puzzle Factory.
                    </p>
                    <h2>
                        6. Disclaimer of Warranties
                    </h2>
                    <p>
                        6.1. The Website is provided on an "as-is" and "as-available" basis.
                        The Puzzle Factory makes no warranties or representations of any kind,
                        whether express or implied, regarding the accuracy, reliability,
                        or completeness of the content on the Website.
                    </p>
                    <p>
                        6.2. The Puzzle Factory disclaims all warranties, including but not limited to,
                        implied warranties of merchantability, fitness for a particular purpose, and non-infringement.
                    </p>
                    <h2>
                        7. Limitation of Liability
                    </h2>
                    <p>
                        7.1. The Puzzle Factory shall not be liable for any direct, indirect, incidental,
                        special, or consequential damages resulting from your use of or inability to use the Website,
                        including but not limited to, damages for loss of profits, goodwill, use, data,
                        or other intangible losses.
                    </p>
                    <h2>
                        8. Contact Information
                    </h2>
                    <p>
                        If you have any questions or concerns regarding these terms and conditions,
                        please contact us at info@thepuzzlefactory.co
                    </p>

                </div>
            </div>
        </section>

    );
}

export default TermsofService;