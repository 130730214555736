import React from "react";
import UploadQnFile from "./Upload-Qn-File";
import UploadQuestion from "./Upload-Qn";

function AddQuestion() {
    return (
        <React.Fragment>
            <section className="container">

                {/* ---------------------------------     Add Quiz File       ----------------------------------*/}
                <UploadQnFile />

                {/* ---------------------------------     Page Divider       ----------------------------------*/}
                <div className="text-center w-100" style={{ height: "15px", borderBottom: "1px solid #dbd7df" }}>
                    <span className="bg-white px-2">
                        or
                    </span>
                </div>

                {/* ---------------------------------     Add Question       ----------------------------------*/}
                <UploadQuestion />




            </section>
        </React.Fragment>
    );
}

export default AddQuestion;