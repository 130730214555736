import React from "react";
import { Navigate, useLocation, Outlet } from "react-router-dom";

function PrivateRoute({ authenticated }) {
  const location = useLocation();

  return (
    authenticated ?
      <Outlet /> :
      <Navigate to="/login" replace state={{ from: location.pathname }} 
      />
  );
}

export default PrivateRoute;
