import React from "react";

function YtVideo() {
    return (
        <section className="ytVideo-section">
            <div className="container p-3 mb-4">
                <h3 className="text-center fs-1 fw-bold" style={{ color: "#213a5c" }}>WATCH OUR LATEST VIDEO</h3>
                <div className="embed-responsive embed-responsive-16by9 mt-3 d-flex justify-content-center">
                    <iframe src="https://www.youtube.com/embed/LANed3CFALs?si=K-TJ_PPUTO1WZI5J"
                        title="The Puzzle Factory Quiz Video on Youtube" className="embed-responsive-item w-100 ytVideo"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen>
                    </iframe>
                </div>
            </div>
        </section>
    );
}

export default YtVideo;