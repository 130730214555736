import React, { useEffect } from "react";
import { Alert } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import googleLogo from "../../assets/images/google-logo.png";
import tpfLogo from "../../assets/images/logo small.png";
import { GOOGLE_AUTH_URL, PREV_LOCATION } from "../../constants/config";

function Login({ authenticated }) {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        // If the OAuth2 login encounters an error, the user is redirected to the /login page with an error.
        // Here we display the error and then remove the error query parameter from the location.
        if (location.state && location.state.error) {
            console.log("error in oauth2 : ", location.state);
            setTimeout(() => {
                Alert.error(location.state.error, {
                    timeout: 5000
                });
                navigate(location.pathname, { replace: true });
            }, 100);
        }
    }, [location.state, navigate]);

    // Get redirect location or provide fallback
    const from = location.state?.from || "/";
    localStorage.setItem(PREV_LOCATION, from);

    return (
        <React.Fragment>
            <section className="socialLogin">
                <div className="social-login" style={{ marginTop: "100px", marginBottom: "100px" }}>
                    <div className="d-grid gap-2 col-8 col-md-8 col-lg-4 mx-auto">
                        <img className="social-btn-img mx-auto mb-3" src={tpfLogo} alt="ThePuzzleFactory.co" style={{ height: "100px" }} />
                        <a className="btn btn-block btn-primary social-btn" href={GOOGLE_AUTH_URL}>
                            <img className="social-btn-img" src={googleLogo} alt="Google" style={{ height: "32px", marginRight: "8px" }} />
                            Log in with Google
                        </a>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
}

export default Login;