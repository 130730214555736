import React, { useState, useEffect } from "react";
import { API_BASE_URL } from "../../constants/config"

function CreateQuiz() {
    const [isFormValidated, setIsFormValidated] = useState(false);
    const [isCreationSuccess, setIsCreationSuccess] = useState(false);

    const handleAddQuiz = (event) => {
        'use strict'

        event.preventDefault();
        var forms = document.querySelectorAll('.needs-validation')

        // Fetch all the forms we want Bootstrap validation to be applied. Loop over them and prevent submission
        Array.prototype.slice.call(forms)
            .forEach(function (form) {
                form.addEventListener('submit', function (event) {
                    if (!form.checkValidity()) {
                        event.preventDefault()
                        event.stopPropagation()
                    }
                    form.classList.add('was-validated')
                }, false)
            })


        const form = event.target;
        if (form.checkValidity()) {
            const formData = new FormData(event.target);
            setIsFormValidated(true);

            const requestBody = {
                title: formData.get('title'),
                category: formData.get('category')
            };

            fetch(`${API_BASE_URL}/quiz/createQuiz`, {
                method: form.method,
                body: JSON.stringify(requestBody),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
                .then(response => response.json())
                .then(json => {
                    setIsCreationSuccess(true);
                    form.reset();
                    const forms = document.querySelectorAll('.needs-validation');
                    forms.forEach(form => {
                        form.classList.remove('was-validated');
                    });
                })
                .catch(error => console.log('Error reported : ' + error.message));
        }

    };

    useEffect(() => {
        if (isCreationSuccess) {
            const timer = setTimeout(() => {
                setIsCreationSuccess(false);
            }, 3000);

            return () => {
                clearTimeout(timer);
            };
        }
    }, [isCreationSuccess]);

    return (
        <section className="create-quiz">
            <h3 className="text-center" style={{ marginTop: "80px" }}><strong>Create New Quiz</strong></h3>
            <div className="container mt-4">
                <form className="needs-validation" noValidate onSubmit={handleAddQuiz} method="post">
                    <div className="mb-4">
                        <input type="text" id="form3Example1" className="form-control" placeholder="Title" name="title" required />
                        <div className="invalid-feedback">
                            Please provide a quiz title
                        </div>
                    </div>
                    <div className="mb-4">
                        <input type="text" id="form3Example1" className="form-control" placeholder="Description" name="description" />
                    </div>
                    <div className="mb-4">
                        <select className="form-select" aria-label="Category select" defaultValue="" name="category" required>
                            <option value="">Select a Category</option>
                            <option value="Science">Science</option>
                            <option value="Sports">Sports</option>
                            <option value="Technology">Technology</option>
                            <option value="Space">Space</option>
                            <option value="Space">Automobiles</option>
                            <option value="History">History</option>
                        </select>
                        <div className="invalid-feedback">
                            Please add a question
                        </div>
                    </div>

                    <div className="d-grid">
                        <button type="submit" className="btn btn-primary mb-4">
                            Submit
                        </button>
                    </div>

                </form>
                {
                    isCreationSuccess && (
                        <div className="alert alert-success" role="alert">
                            <strong>Well done!</strong> Quiz created successfully
                        </div>
                    )}
            </div>
        </section>
    );
}

export default CreateQuiz;