import React from 'react';
import Gamer from "../../assets/images/robo.png";

function ScoreBoard({ scores, xPlaying }) {
  const { xScore, oScore } = scores;

  return (
    <div>
      <div className="scoreboard d-flex justify-content-center p-2">
        <div className={`p-4 m-1 score x-score ${!xPlaying && "inactive"}`} style={{ boxShadow: "0px 0px 8px #888888" }}>
          <img src={Gamer} className="rounded-circle" style={{ height: "50px", width: "50px" }} alt="Image By masadepan on freepik" />
          <h5 className='fw-bold mt-2' style={{color:"#097969"}}>X</h5>
          <div className="">{xScore}</div>
        </div>
        <div className='p-3'>
          <h5 className=''>v/s</h5>
        </div>
        <div className={`p-4 m-1 score o-score ${xPlaying && "inactive"}`} style={{ boxShadow: "0px 0px 8px #888888" }}>
          <img src={Gamer} className="rounded-circle" style={{ height: "50px", width: "50px" }} alt="Image By masadepan on freepik" />
          <h5 className='fw-bold mt-2' style={{color:"#FF7F50"}}>O</h5>
          <div className="">{oScore}</div>
        </div>
      </div>
      <div className={`p-3 m-1 ${xPlaying ? "" : "hidden"}`}>
        {
          xPlaying ? (
            <h2 className="fw-bold" style={{color:"#097969"}}>X's turn</h2>
          ) : (
            <h2 className="fw-bold" style={{color:"#FF7F50"}}>O's turn</h2>
          )
        }
      </div>
    </div>
  )
}

export default ScoreBoard;
