import React from "react";

function AboutUs() {
    return (
        <section>
            <div className="about-us">
                <h6 className="text-center mt-5 fs-5 lh-base px-4" style={{ color: "#1a488e" }}>
                Welcome to The Puzzle Factory, your ultimate destination for engaging quizzes
                    on a variety of topics! Our platform offers a diverse range of challenges that cater
                    to both your curiosity and knowledge hunger. Whether you're a student looking for
                    interactive learning or just seeking some brain-teasing fun, we've got something for everyone.
                    From captivating trivia questions to insightful quizzes, you'll find it all here.
                    Let's embark on a journey of exploration and learning through quizzes!
                </h6>

            </div>
        </section>

    );
}

export default AboutUs;