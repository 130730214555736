import React, { useState, useEffect } from "react";
import { API_BASE_URL } from "../../constants/config";
import UserProfileHead from "../../assets/images/user-head-icon.png";

function UserScoreTable() {
    const [userScoreData, setUserScoreData] = useState([]);

    useEffect(() => {
        fetch(`${API_BASE_URL}/public/getLeaderBoard`)
            .then(response => response.json())
            .then(data => setUserScoreData(data))
            .catch(error => console.error('Error fetching quiz data:', error));
    }, []);

    return (
        <div className="userscoretable">
            <table className="table userscore align-middle mb-0">
                <thead>
                    <tr>
                        <th scope="col" className="leaderBoardData">PLAYER</th>
                        <th scope="col" className="leaderBoardData">TOTAL SOLVED</th>
                        <th scope="col" className="leaderBoardData">TOTAL SCORE</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        userScoreData.length !== 0 ? (
                            userScoreData.map(
                                data =>
                                (
                                    <tr>
                                        <td className="leaderBoardData">
                                            <div className="d-flex align-items-center">
                                                <img
                                                    src={data.imageUrl === "hidden" ? UserProfileHead : data.imageUrl}
                                                    alt=""
                                                    style={{ width: "45px", height: "45px" }}
                                                    className="rounded-circle"
                                                />
                                                <div className="ms-3">
                                                    <p className="fw-bold mb-1">{data.username}</p>

                                                </div>
                                            </div>
                                        </td>
                                        <td className="leaderBoardData"><p className="fw-normal mb-1">{data.totalSolved}</p></td>
                                        <td className="leaderBoardData">{data.totalScore}</td>
                                    </tr>
                                )
                            )
                        ) : (
                            <React.Fragment>
                                <tr>
                                    <td className="leaderBoardData">
                                        <div className="d-flex align-items-center">
                                            <img
                                                src="https://mdbootstrap.com/img/new/avatars/8.jpg"
                                                alt=""
                                                style={{ width: "45px", height: "45px" }}
                                                className="rounded-circle"
                                            />
                                            <div className="ms-3">
                                                <p className="fw-bold mb-1">johndoe</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="leaderBoardData"><p className="fw-normal mb-1">5</p></td>
                                    <td className="leaderBoardData">18</td>
                                </tr>
                                <tr>
                                    <td className="leaderBoardData">
                                        <div className="d-flex align-items-center">
                                            <img
                                                src="https://mdbootstrap.com/img/new/avatars/6.jpg"
                                                className="rounded-circle"
                                                alt=""
                                                style={{ width: "45px", height: "45px" }}
                                            />
                                            <div className="ms-3">
                                                <p className="fw-bold mb-1">alexray</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="leaderBoardData"><p className="fw-normal mb-1">4</p></td>
                                    <td className="leaderBoardData">15</td>
                                </tr>
                                <tr>
                                    <td className="leaderBoardData">
                                        <div className="d-flex align-items-center">
                                            <img
                                                src="https://mdbootstrap.com/img/new/avatars/7.jpg"
                                                className="rounded-circle"
                                                alt=""
                                                style={{ width: "45px", height: "45px" }}
                                            />
                                            <div className="ms-3">
                                                <p className="fw-bold mb-1">katehunington</p>

                                            </div>
                                        </div>
                                    </td>
                                    <td className="leaderBoardData"><p className="fw-normal mb-1">2</p></td>
                                    <td className="leaderBoardData">10</td>
                                </tr>
                            </React.Fragment>
                        )}
                </tbody>
            </table>
        </div>
    );
}

export default UserScoreTable;