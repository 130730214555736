import React from 'react';
import "../../page-styles/Quiz-Card.css";
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from '@fortawesome/free-regular-svg-icons';
import QuizImage from "../../assets/images/image2.png";

function QuizCard({ id, image, title, description }) {
    const navigate = useNavigate();

    const handlePlayClick = () => {
        navigate(`/quiz/${encodeURIComponent(id)}/${encodeURIComponent(title)}`);
    };

    return (
        <div className="card text-center quiz-card h-100" style={{ background: "rgba(255, 255, 255, 0.5)" }} data-aos="zoom-in-up">
            <div className="card-bg-img" onClick={handlePlayClick}>
                <img src={QuizImage} alt={title} className="card-img-top" />
                <div className="overlay"></div>
                <div className="card-img-overlay">
                    <FontAwesomeIcon icon={faHeart} style={{ color: "#eff1f5", float: "right" }} />
                </div>
            </div>
            <div className="card-body">
                <h5 className="card-title fw-bold" style={{ color: "#007cb9" }}>{title}</h5>
                <p className="card-text text-muted">{description}</p>
                <div className="card-footer bg-transparent">
                    <button className="btn btn-primary" onClick={handlePlayClick}>Play Now!</button>
                </div>

            </div>
        </div>
    );
}

export default QuizCard;