import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';

function MoveToTopButton() {
    const [showButton, setShowButton] = useState(false);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setShowButton(true);
            } else {
                setShowButton(false);
            }
        };
        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <React.Fragment>
            {
                showButton && (
                    <button className={`btn btn-outline-primary scroll-to-top rounded-circle 
                    ${showButton ? 'fade-in' : 'fade-out'}`} onClick={scrollToTop}>
                        <FontAwesomeIcon icon={faChevronUp} />
                    </button>
                )}

        </React.Fragment>
    );
}

export default MoveToTopButton;
