import React from "react";
import Slider from "../home-inc/Slider";
import HomeQuiz from "../home-inc/Home-Quiz";
import Quote from "../home-inc/Quote";
import BlogIntro from "../home-inc/Blog-Intro";
import AboutUs from "./About-Us";
import Intro from "../home-inc/Intro";
import YtVideo from "../home-inc/YtVideo";

function Home() {
    return (
        <React.Fragment>
            <Slider />
            <AboutUs />
            <Intro />
            {/* ======================================= QUOTE ================================================= */}

            <Quote />

            {/* ======================================= EXPLORE QUIZZES ================================================= */}

            <HomeQuiz />


            {/* ======================================= CHALLENGES ================================================= */}

            {/* <ChallengesIntro /> */}

            {/* ======================================= LATEST BLOG POSTS ================================================= */}

            <BlogIntro />

            {/* ======================================= YT VIDEO ================================================= */}

            <YtVideo />

            {/* ======================================= CONTACT US ================================================= */}

            {/* <ContactUs /> */}
            {/* </div>
            </main> */}

        </React.Fragment>
    );
}

export default Home;