import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { API_BASE_URL } from "../../constants/config";
import { postReqJsonOptions } from "../../common/ApiUtils";

function Settings({ currentUser }) {
    const [hideProfilePic, setHideProfilePic] = useState(!currentUser.userPreferences.hideProfilePic);
    const [hideUserScore, setHideUserScore] = useState(!currentUser.userPreferences.hideUserScore);
    const [isSaveChangesSuccess, setIsSaveChangesSuccess] = useState(false);

    useEffect(() => {
        if (isSaveChangesSuccess) {
            const timer = setTimeout(() => {
                setIsSaveChangesSuccess(false);
            }, 5000);

            return () => {
                clearTimeout(timer);
            };
        }
    }, [isSaveChangesSuccess]);

    const toggleHideUserScore = () => {
        // Toggle the hideUserScore state when the checkbox is changed
        setHideUserScore(!hideUserScore);
    }

    const toggleHideProfilePic = () => {
        // Toggle the hideProfilePic state when the checkbox is changed
        setHideProfilePic(!hideProfilePic);
    }

    const handleUserPreferences = (event) => {
        'use strict'

        event.preventDefault();

        const form = event.target;

        const requestBody = {
            email: currentUser.user.email,
            hideProfilePic: !hideProfilePic,
            hideUserScore: !hideUserScore
        };

        console.log(requestBody);

        fetch(`${API_BASE_URL}/user/updatePreferences`, {
            body: JSON.stringify(requestBody),
            ...postReqJsonOptions()
        })
            .then(response => response.json())
            .then(json => {
                currentUser.userPreferences = json;
                setIsSaveChangesSuccess(true);
            })
            .catch(error => console.log('Error reported : ' + error.message));
    }


    return (
        <div id="settings-content" className="p-4 p-md-5 pt-5">
            <h2 className="mb-4">
                Preferences
            </h2>

            <h4 className="mb-3">
                <FontAwesomeIcon icon={faEye} className="me-3" style={{ color: "rgba(0, 0, 0, .7)" }} />
                Visibility
            </h4>

            <form onSubmit={handleUserPreferences} method="post">
                <div className="form-check-reverse form-switch mb-3 d-flex justify-content-between fs-5">
                    <label className="form-check-label text-muted" for="flexSwitchCheckChecked">
                        Show Score on Leaderboard
                    </label>
                    <input className="form-check-input" type="checkbox" role="switch"
                        id="flexSwitchCheckChecked" checked={hideUserScore}
                        onChange={toggleHideUserScore} />
                </div>

                <div className="form-check-reverse form-switch mb-3 d-flex justify-content-between fs-5">
                    <label className="form-check-label text-muted" for="flexSwitchCheckChecked">
                        Show profile picture
                    </label>
                    <input className="form-check-input" type="checkbox" role="switch"
                        id="flexSwitchCheckChecked" checked={hideProfilePic}
                        onChange={toggleHideProfilePic} />
                </div>

                <div>
                    <button type="submit" className="btn btn-primary">
                        Save Changes
                    </button>
                </div>
            </form>
            {
                isSaveChangesSuccess && (
                    <div className="alert alert-success mt-3" role="alert">
                        <strong>Preferences Updated!</strong>
                    </div>
                )}

            {/* <hr className="my-3" /> */}
        </div>
    );
}

export default Settings;