import React from "react";

function StayTuned() {
    return (
        <div className="stayTuned text-white">
            {/* <h1 className="" style={{ marginTop: "80px" }}>Stay Tuned!</h1> */}
            <h1 className="" style={{ marginTop: "80px" }}>Stay Tuned!</h1>
        </div>
    );
}

export default StayTuned;