import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faYoutube, faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';

function SocialWidgets() {
    const youtubeChannelId = "UCYkP7CdzagyzShq6IiePhXQ";
    const [subscribers, setSubscribers] = useState(null);
    const API_KEY = "AIzaSyDmTojFDOFUpjlr6e-lnKT5sHBn5UD2bOI";

    useEffect(() => {
        fetch(
            `https://www.googleapis.com/youtube/v3/channels?part=statistics&id=${youtubeChannelId}&key=${API_KEY}`
          )
            .then((data) => data.json())
            .then((result) => {
              setSubscribers(result.items[0].statistics.subscriberCount);
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
                setSubscribers(300);
            });
    }, []);



    return (
        <section className="social-widgets">
            <div className="row g-2">
                <div className="col-4" data-aos="zoom-in-up">
                    <a href="/"
                        className="fb-widget rounded text-center p-3 d-block"
                        style={{ textDecoration: "none", color: "#fff" }}>
                            <FontAwesomeIcon icon={faFacebook} className="mb-2"/>
                        <h6 className="m-0">12</h6>
                        <span className="small">Fans</span>
                    </a>
                </div>

                <div className="col-4" data-aos="zoom-in-up">
                    <a href="https://www.instagram.com/thepuzzlefactory.co/"
                        className="insta-widget rounded text-center p-3 d-block"
                        style={{ textDecoration: "none", color: "#fff" }}>
                        <FontAwesomeIcon icon={faInstagram} className="mb-2" />
                        <h6 className="m-0">3</h6>
                        <span className="small">Followers</span>
                    </a>
                </div>
                <div className="col-4" data-aos="zoom-in-up">
                    <a href="https://youtube.com/@ThePuzzleFactory"
                        className="yt-widget rounded text-center p-3 d-block"
                        style={{ textDecoration: "none", color: "#fff" }}>
                        <FontAwesomeIcon icon={faYoutube}  className="mb-2" />
                        <h6 className="m-0">{subscribers}</h6>
                        <span className="small">Subs</span>
                    </a>
                </div>
            </div>
        </section>
    );
}

export default SocialWidgets;