import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from '@fortawesome/free-solid-svg-icons';
import Blog1 from "../../assets/images/olav-ahrens-rotne-4Ennrbj1svk-unsplash.jpg";
import Blog2 from "../../assets/images/pexels-mikael-blomkvist-6476258.jpg";
import Blog3 from "../../assets/images/pexels-beyzaa-yurtkuran-13533591.jpg";
import Blog4 from "../../assets/images/pexels-keira-burton-6623833.jpg";

function BlogIntro() {
    return (
        <section className="mb-5">
            <h3 className="text-center" style={{ color: "#0e2038" }}><strong>LATEST BLOG POSTS</strong></h3>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-6 mt-3 blog-post d-flex align-items-stretch">
                        <div className="card text-white blog-card overflow-hidden position-relative">
                            <img src={Blog1} className="blog-image card-img h-100" alt="olav-ahrens-rotne-4Ennrbj1svk-unsplash"/>

                            {/* Featured Icon */}
                            <span className="blog-featured" title="Featured Post">
                                <FontAwesomeIcon icon={faStar} style={{ color: "#fff" }} />
                            </span>


                            <div className="card-img-overlay featured-card-overlay">
                                <a href="https://blog.thepuzzlefactory.co/how-puzzles-enhance-memory-and-critical-thinking"
                                    target="_blank" rel="noopener noreferrer" className="title-link text-white text-decoration-none">
                                    <h2 className="card-title fw-bold fs-1">How Puzzles Enhance Memory and Critical Thinking</h2>
                                </a>
                                <p className="card-text">Last updated 3 days ago</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="row mt-3">
                            <div className="col">
                                <div className="card text-white blog-card overflow-hidden position-relative">
                                    <img src={Blog2} className="blog-image w-100" alt="pexels-mikael-blomkvist-6476258" />
                                    <div className="card-img-overlay featured-card-overlay">
                                        <a href="https://blog.thepuzzlefactory.co/online-games-for-virtual-team-building"
                                            target="_blank" rel="noopener noreferrer" className="title-link text-white text-decoration-none">
                                            <h2 className="card-title fw-bold">10 Refreshing Online Games for Your Virtual Office Coffee Breaks</h2>
                                        </a>
                                        <p className="card-text">In this digital age, the traditional office coffee break has evolved into a virtual gathering where colleagues connect, share, and unwind.</p>
                                        <p className="card-text">Last updated 2 days ago</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 mt-3">
                                <div className="card text-white blog-card overflow-hidden position-relative">
                                    <img src={Blog3} className="blog-image" alt="pexels-beyzaa-yurtkuran-13533591" />
                                    <div className="card-img-overlay featured-card-overlay">
                                        <a href="https://blog.thepuzzlefactory.co/evolution-of-crossword-puzzles"
                                            target="_blank" rel="noopener noreferrer" className="title-link text-white text-decoration-none">
                                            <h4 className="card-title fw-bold">The Evolution of Crossword Puzzles</h4>
                                        </a>
                                        <h2 className="card-title fs-3"></h2>
                                        <p className="card-text">Last updated 3 mins ago</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 mt-3">
                                <div className="card text-white blog-card overflow-hidden position-relative">
                                    <img src={Blog4} className="blog-image" alt="pexels-keira-burton-6623833" />
                                    <div className="card-img-overlay featured-card-overlay">
                                        <a href="https://blog.thepuzzlefactory.co/10-toys-for-child-cognitive-development"
                                            target="_blank" rel="noopener noreferrer" className="title-link text-white text-decoration-none">
                                            <h4 className="card-title fw-bold">Brain Booster Toys for Children</h4>
                                        </a>
                                        <p className="card-text">Last updated 3 mins ago</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default BlogIntro;