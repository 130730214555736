import React, { useEffect, useState, useRef } from "react";
import "../../../App.css";
import "../../page-styles/Quiz.css";
import StayTuned from "./StayTuned";
import { useParams } from 'react-router-dom';
import { API_BASE_URL } from "../../constants/config";
import { getRequestOptions, postReqJsonOptions } from "../../common/ApiUtils";

function Quiz({ currentUser }) {
    const [questions, setQuestions] = useState([]);
    const [showStayTuned, setShowStayTuned] = useState(false);
    const { quizId, selectedQuiz } = useParams();
    const parsedQuizId = parseInt(quizId, 10); // Parse the string to a long value
    const nextButtonRef = useRef(null); // Ref to the next button
    const [quizFinished, setQuizFinished] = useState(false);

    useEffect(() => {
        fetch(`${API_BASE_URL}/qn/getAllQuestions/${parsedQuizId}`, getRequestOptions())
            .then(response => {
                if (!response.ok && response.status === 400) {
                    setShowStayTuned(true);
                }
                return response.json();
            })
            .then(data => {
                if (!showStayTuned) {
                    setQuestions(data);
                }
            })
            .catch(error => console.error('Error fetching quiz questions:', error));
    }, [quizId]);

    useEffect(() => {
        const questionElement = document.getElementById("question");
        const answerButtons = document.getElementById("answer-buttons");

        let currentQuestionIndex = 0;
        let score = 0;

        const startQuiz = () => {
            currentQuestionIndex = 0;
            score = 0;
            if (nextButtonRef.current !== null) {
                nextButtonRef.current.innerHTML = "Next";
            }
            showQuestion();
        };

        const showQuestion = () => {
            resetState();
            if (currentQuestionIndex < questions.length) {
                const currentQuestion = questions[currentQuestionIndex];
                const questionNo = currentQuestionIndex + 1;
                questionElement.innerHTML = `${questionNo}. ${currentQuestion.problem}`;

                currentQuestion.choices.forEach((choice, index) => {
                    const button = document.createElement("button");
                    button.innerHTML = choice;
                    button.classList.add("answer-btn");
                    answerButtons.appendChild(button);

                    button.addEventListener("click", () => selectAnswer(index));
                });
            } else {
                setQuizFinished(true);
                showScore();
            }
        };

        const resetState = () => {
            if (nextButtonRef.current !== null) {
                nextButtonRef.current.style.display = "none"
                while (answerButtons.firstChild) {
                    answerButtons.removeChild(answerButtons.firstChild);
                }
            }
        };

        const selectAnswer = (selectedIndex) => {
            const selectedBtn = answerButtons.children[selectedIndex];
            const isCorrect = questions[currentQuestionIndex].choices[selectedIndex] === questions[currentQuestionIndex].correctOption;

            if (isCorrect) {
                selectedBtn.classList.add("correct");
                score++;
            } else {
                selectedBtn.classList.add("incorrect");
            }

            Array.from(answerButtons.children).forEach((button, index) => {
                if (questions[currentQuestionIndex].choices[index] === questions[currentQuestionIndex].correctOption) {
                    button.classList.add("correct");
                }
                button.disabled = true;
            });

            if (currentQuestionIndex === questions.length - 1) {
                // Display "Play Again" button for the last question
                nextButtonRef.current.innerHTML = "Play Again";
            } else {
                // Display "Next" button for other questions
                nextButtonRef.current.innerHTML = "Next";
            }

            nextButtonRef.current.style.display = "block";
        };

        const showScore = () => {
            resetState();
            if (questionElement != null) {
                questionElement.innerHTML = `You scored ${score} out of ${questions.length}!`;
                if (quizFinished) {
                    nextButtonRef.current.innerHTML = "Play Again";
                    nextButtonRef.current.style.display = "block";
                    addQuizScore(score);
                }
            }
        };

        const addQuizScore = (score) => {
            const requestBody = {
                email: currentUser.email,
                quizId: quizId,
                score: score
            };

            fetch(`${API_BASE_URL}/addScore`, {
                ...postReqJsonOptions(),
                body: JSON.stringify(requestBody),
            })
                .then(response => response.json())
                .then(json => {
                    console.log(json);
                })
                .catch(error => console.log('Error in adding score : ' + error.message));
        };

        // Set up the event listener when the component mounts
        if (nextButtonRef.current) {
            nextButtonRef.current.addEventListener("click", () => {
                if (currentQuestionIndex < questions.length) {
                    currentQuestionIndex++;
                    showQuestion();
                } else {
                    startQuiz();
                }
            });
        }

        startQuiz();
    }, [questions]);


    return (
        <section className="quiz-content">
            {
                showStayTuned ? (
                    <StayTuned />
                ) : (
                    <div className="quiz-app">
                        <h1>{selectedQuiz}</h1>
                        <div className="quiz">
                            <h2 id="question">Question goes here</h2>
                            <div className="answer-buttons" id="answer-buttons">
                                <button className="btn answer-btn">Answer 1</button>
                                <button className="btn answer-btn">Answer 2</button>
                                <button className="btn answer-btn">Answer 3</button>
                                <button className="btn answer-btn">Answer 4</button>
                            </div>
                            <button ref={nextButtonRef} id="next-btn">Next</button>
                        </div>
                    </div>
                )
            }
        </section>
    );
}

export default Quiz;