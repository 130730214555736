import React from "react";
import "../page-styles/hexdots.css";

export default function LoadingIndicator() {
  return (
    <React.Fragment>
      <div className="container p-5">
      <div className="loading-indicator text-center" style={{ marginTop: "80px" }}>
        Loading ...
      </div>
      <div className="d-flex justify-content-center align-items-center">
        <span className="hexdots-loader" style={{ marginTop: "80px", marginBottom: "80px" }}>
          Loading&#8230;
        </span>
      </div>
      </div>
    </React.Fragment>
  );
}
