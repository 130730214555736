import React, { useState, useEffect } from "react";
import { API_BASE_URL } from "../../constants/config";
import { postRequestOptions } from "../../common/ApiUtils";

function UploadQnFile() {
    const [isFormValidated, setIsFormValidated] = useState(false);
    const [selectedQuiz, setSelectedQuiz] = useState("");
    const [quizzes, setQuizzes] = useState([]);
    const [isCreationSuccess, setIsCreationSuccess] = useState(false);

    useEffect(() => {
        fetchQuizzes().then(data => {
            setQuizzes(data);
        });
    }, []);

    const handleSelectedQuiz = (event) => {
        setSelectedQuiz(event.target.value);
    };

    const handleFileUpload = (event) => {
        event.preventDefault();

        const formInput = document.querySelectorAll('.needs-validation');

        Array.prototype.slice.call(formInput)
            .forEach(function (form) {
                form.addEventListener('submit', function (event) {
                    if (!form.checkValidity()) {
                        event.preventDefault()
                        event.stopPropagation()
                    }
                    form.classList.add('was-validated')
                }, false)
            })

        const form = event.target;

        if (form.checkValidity()) {
            const formData = new FormData(event.target);

            setIsFormValidated(true);

            const dataToSend = {
                quizId: selectedQuiz,
                type: formData.get('typeSelect')
            };

            const uploadData = new FormData();
            uploadData.append("data", new Blob([JSON.stringify(dataToSend)], { type: "application/json" }));
            uploadData.append("files", formData.get('fileSelect'));

            fetch(`${API_BASE_URL}/qn/uploadQuestionFile`, {
                ...postRequestOptions(),
                body: uploadData,
            })
                .then(response => response.status)
                .then(status => {
                    if (status === 201) {
                        setIsCreationSuccess(true);
                        form.reset();
                        setSelectedQuiz("");
                        const forms = document.querySelectorAll('.needs-validation');
                        forms.forEach(form => {
                            form.classList.remove('was-validated');
                        });
                    }
                })
                .catch(error => console.log('Error reported : ' + error.message));
        }
    };

    const fetchQuizzes = async () => {
        try {
            const response = await fetch(`${API_BASE_URL}/quiz/getAllQuizzes`);
            const quizzes = await response.json();
            return quizzes;
        } catch (error) {
            console.error('Error fetching quizzes:', error);
            return [];
        }
    };

    useEffect(() => {
        if (isCreationSuccess) {
            const timer = setTimeout(() => {
                setIsCreationSuccess(false);
            }, 3000);

            return () => {
                clearTimeout(timer);
            };
        }
    }, [isCreationSuccess]);

    return (
        <section className="uploadQnFile">
            <form className="row g-3 needs-validation" noValidate onSubmit={handleFileUpload} method="post" encType="multipart/form-data">
                <div className="add-question-file" style={{ marginTop: "90px" }}>
                    <h3 className="text-center"><strong>Add Question File</strong></h3>
                    <div className="row mb-4 mt-4">

                        <div className="col-lg-3">
                            <select className="form-select mb-4 text-muted" aria-label="Type select" name="typeSelect" required>
                                <option value="">Select Question Type</option>
                                <option value="MCQ">MCQ</option>
                            </select>
                            <div className="invalid-feedback">Please select a question type.</div>
                        </div>

                        <div className="col-lg-3">
                            <select className="form-select mb-4 text-muted has-validation"
                                aria-label="Type select" name="quizSelect" value={selectedQuiz} onChange={handleSelectedQuiz} required>
                                <option value="">Select Quiz</option>
                                {
                                    quizzes.map(
                                        quiz => (
                                            <option key={quiz.id} value={quiz.id}>{quiz.title}</option>
                                        ))
                                }
                            </select>
                            <div className="invalid-feedback">Please select a quiz.</div>
                        </div>

                        <div className="col-lg-3">
                            <input className="form-control" type="file" id="formFileMultiple" multiple name="fileSelect" required />
                            <div className="invalid-feedback">
                                Please select a valid json file.
                            </div>
                        </div>

                        <div className="col-lg-3 d-grid">
                            <button type="submit" className="btn btn-primary mb-4">
                                Submit
                            </button>
                        </div>

                    </div>
                </div>
            </form>
            {
                isCreationSuccess && (
                    <div className="alert alert-success" role="alert">
                        <strong>Well done!</strong> Questions added to {selectedQuiz} successfully
                    </div>

                )}
        </section>

    );
}

export default UploadQnFile;