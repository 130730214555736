import { useEffect, useState } from 'react';
import { ACCESS_TOKEN, PREV_LOCATION } from '../../constants/config';
import { useLocation, useNavigate } from 'react-router-dom';

function OAuth2RedirectHandler() {
    const location = useLocation();
    const [token, setToken] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    function getUrlParameter(name) {
        name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
        var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');

        var results = regex.exec(location.search);
        return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    };

    useEffect(() => {
        const retrievedToken = getUrlParameter('token');
        const retrievedError = getUrlParameter('error');

        if (retrievedToken) {
            localStorage.setItem(ACCESS_TOKEN, retrievedToken);
            setToken(retrievedToken);
        }

        if(retrievedError) {
            setError(retrievedError);
            console.log("Error reported : ", error);
        }
    }, []);

    if (token) {
        const redirectTo = localStorage.getItem(PREV_LOCATION) ? localStorage.getItem(PREV_LOCATION) : "/";
        return navigate(redirectTo);
    }
}


export default OAuth2RedirectHandler;