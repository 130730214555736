import React, { useState } from "react";
import Slider1 from '../../assets/images/slider-2.jpg';
import { faUser, faGear } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UserProfileHead from "../../assets/images/user-head-icon.png";
import Settings from "./Settings";
import Account from "./Account";

function Profile({ currentUser }) {
    // State to track the active navigation item
    const [activeNavItem, setActiveNavItem] = useState("account");

    // Function to handle navigation item click
    const handleNavItemClick = (navItem) => {
        setActiveNavItem(navItem);
    };

    return (
        <section className="profile">
            <div className="container mb-5" style={{ marginTop: "20px" }}>
                <div className="row">
                    <div className="col-lg-3">
                        <div className="sidebar bg-white overflow-hidden">
                            <div className="text-center py-4" style={{ backgroundImage: `url(${Slider1})` }}>
                                <div className="user-logo">
                                    <img
                                        src={currentUser.userPreferences.hideProfilePic === true ? UserProfileHead : currentUser.user.imageUrl}
                                        alt={currentUser.user.name}
                                        className="rounded-circle"
                                        width="150"
                                    />
                                    <h3 className="text-white mt-3">{currentUser.user.name}</h3>
                                </div>
                            </div>
                            <div className="list-group list-group-flush mx-3 my-4">
                                <a
                                    onClick={() => handleNavItemClick("account")}
                                    className={`list-group-item list-group-item-action py-2 
                                        ${activeNavItem === "account" ? "active" : ""}`}
                                    aria-current="true">
                                    <FontAwesomeIcon icon={faUser} style={{ marginRight: "8px" }} />
                                    <span>Account</span>
                                </a>
                                <a
                                    onClick={() => handleNavItemClick("settings")}
                                    className={`list-group-item list-group-item-action py-2 
                                        ${activeNavItem === "settings" ? "active" : ""}`}>
                                    <FontAwesomeIcon icon={faGear} style={{ marginRight: "8px" }} />
                                    <span>Settings</span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-9">
                        {
                            activeNavItem === "account" ?
                                (
                                    <Account currentUser={currentUser}/>
                                ) : (
                                    <Settings currentUser={currentUser}/>
                                )
                        }
                    </div>
                </div>
            </div>



        </section >

    );
}

export default Profile;