import React from "react";
import Tpf from "../assets/images/tpf-white.png"
import ArcheryBoard from "../assets/images/archeryboard.png";
import Eye from "../assets/images/eye.png";
import PuzzleClub from "../assets/images/puzzleclub.png";

function MissionVision() {
    return (
        <section className="missionvision">
            <div className="container overflow-hidden text-center"
                style={{ marginTop: "80px", marginBottom: "20px", boxShadow: "2px 2px 20px black", borderRadius: "50px" }}>
                <div className="row">
                    <div className="col-md-4 sidebar-left">
                        <div className="d-block mt-5">
                            <img src={Tpf} alt="Logo" height="200" className="mt-5" />
                        </div>
                        <div className="d-block mt-5">
                            <img src={PuzzleClub} alt="Logo" className="mt-2 mb-2 w-100" />
                        </div>
                    </div>
                    <div className="col-md-8 mx-auto px-5">
                        <div className="card text-center mt-5">
                            <div className="card-header broadcast text-white">
                                <div className="card-img-block">
                                    <img src={ArcheryBoard} alt="mission-archery" className="archery" />
                                </div>
                                <h2>Mission</h2>
                            </div>
                            <div className="card-body">
                                <p className="card-text">At The Puzzle Factory, our mission is to inspire curiosity,
                                    promote continuous learning, and foster a sense of friendly competition.
                                    We strive to provide a platform where individuals can engage with a diverse range
                                    of quizzes and challenges,expanding their knowledge while enjoying the thrill of discovery.
                                </p>
                            </div>
                        </div>

                        <div className="card text-center mt-5 mb-5">
                            <div className="card-header broadcast text-white">
                                <div className="card-img-block">
                                    <img src={Eye} alt="mission-archery" className="archery" />
                                </div>
                                <h2>Vision</h2>
                            </div>
                            <div className="card-body">
                                <p className="card-text">
                                    Our vision is to create a vibrant online community where learning is a joyful journey.
                                    We aim to be the go-to destination for anyone seeking engaging quizzes and
                                    challenging competitions. Through our content and interactive experiences,
                                    we aspire to empower individuals to unlock their full intellectual potential and
                                    connect with others who share their passion for exploration and discovery.
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </section>

    );
}

export default MissionVision;