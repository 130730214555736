import React, { useState } from "react";
import Board from "./Board";
import ScoreBoard from "./ScoreBoard";
import Hexagon from "../../assets/images/vecteezy_geometric-hexagon.jpg";
import Confetti from "../confetti/Confetti";
import { createConfetti } from "../confetti/Confetti copy";

function TikTacToe() {
    const WIN_CONDITIONS = [
        [0, 1, 2],
        [3, 4, 5],
        [6, 7, 8],
        [0, 3, 6],
        [1, 4, 7],
        [2, 5, 8],
        [0, 4, 8],
        [2, 4, 6]
    ];

    const [xPlaying, setXPlaying] = useState(true);
    const [gameOver, setGameOver] = useState(false);
    const [scores, setScores] = useState({ xScore: 0, oScore: 0 });
    const [winningBoxes, setWinningBoxes] = useState([]);
    const [roundFinished, setRoundFinished] = useState(false);

    const [board, setBoard] = useState(Array(9).fill(null));

    const resetBoard = () => {
        setGameOver(false);
        setBoard(Array(9).fill(null));
    }

    const finishGame = () => {
        setGameOver(false);
        setScores({ xScore: 0, oScore: 0 });
        setBoard(Array(9).fill(null));
    }

    const handleBoxClick = (boxIdx) => {
        // Step 1: Update the board
        const updatedBoard = board.map((value, idx) => {
            if (idx === boxIdx) {
                return xPlaying ? "X" : "O";
            } else {
                return value;
            }
        })

        setBoard(updatedBoard);

        // Step 2: Check if either player has won the game
        const winner = checkWinner(updatedBoard);

        if (winner) {
            if (winner === "O") {
                let { oScore } = scores;
                oScore += 1;
                setScores({ ...scores, oScore });
                setRoundFinished(true);
            } else {
                let { xScore } = scores;
                xScore += 1;
                setScores({ ...scores, xScore });
                setRoundFinished(true);
            }
            setGameOver(true);
            resetBoard();
            // setRoundFinished(false);
        }

        // Step 3: Change active player
        setXPlaying(!xPlaying);
    }

    const checkWinner = (board) => {
        for (let i = 0; i < WIN_CONDITIONS.length; i++) {
            const [x, y, z] = WIN_CONDITIONS[i];

            // Iterate through win conditions and check if either player satisfies them
            if (board[x] && board[x] === board[y] && board[y] === board[z]) {
                // setGameOver(true);
                setWinningBoxes([x, y, z]);
                return board[x];
            }
        }
    }

    return (
        <section className="my-5">

            <div className="container d-block text-center p-2"
                style={{
                    boxShadow: "0px 0px 8px #888888",
                    background: `url(${Hexagon})`, backgroundRepeat: "no-repeat",
                    backgroundSize: "cover", backgroundPosition: "center"
                }}>
                <div className="row p-5">
                    <h1 className="fw-bold fs-1">
                        <span style={{ color: '#097969' }}>Tic</span>
                        <span style={{ color: '#FF7F50' }}> Tac</span>
                        <span style={{ color: '#097969' }}> Toe</span>
                    </h1>
                </div>
                <ScoreBoard scores={scores} xPlaying={xPlaying} />
                <Board board={board} onClick={gameOver ? resetBoard : handleBoxClick} />
                <div className="my-4 d-grid justify-content-center gap-2">
                    <button className="btn btn-primary" onClick={resetBoard}>Reset</button>
                    <button className="btn btn-primary" onClick={finishGame}>Finish Game</button>
                </div>
            </div>

        </section>
    );
}

export default TikTacToe;