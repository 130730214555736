import React, { useEffect, useState } from "react";
import { postReqJsonOptions } from "../common/ApiUtils";
import { API_BASE_URL } from "../constants/config";
import "./Envelopestyle.css";

function ContactUs({ authenticated, currentUser }) {
    const [isSuccess, setIsSuccess] = useState(false);

    useEffect(() => {
        if (isSuccess) {
            const timer = setTimeout(() => {
                setIsSuccess(false);
            }, 5000);

            return () => {
                clearTimeout(timer);
            };
        }
    }, [isSuccess]);

    const submitForm = (event) => {
        'use strict'

        event.preventDefault();
        const messageForm = document.querySelector('.needs-validation');

        messageForm.addEventListener('submit', function (event) {
            if (!messageForm.checkValidity()) {
                event.preventDefault();
                event.stopPropagation();
            }
            messageForm.classList.add('was-validated');
        }, false);

        const form = event.target;
        if (form.checkValidity()) {
            const formData = new FormData(event.target);

            const requestBody = {
                name: formData.get('username'),
                email: formData.get('email'),
                message: formData.get('message'),
            };

            fetch(`${API_BASE_URL}/public/sendEmail`, {
                ...postReqJsonOptions(),
                body: JSON.stringify(requestBody)
            })
                .then(response => response.json())
                .then(json => {
                    console.log(json);
                    setIsSuccess(true);
                    form.reset();
                })
                .catch(error => console.log('Error reported : ' + error.message));
        }
    };

    return (
        <section className="contactus">
            <div className="container p-0 overflow-hidden"
                style={{
                    marginTop: "80px", marginBottom: "20px",
                    boxShadow: "2px 2px 20px black", borderRadius: "20px"
                }}>
                <div className="text-center text-white broadcast p-5">
                    <h1>Write to Us</h1>
                </div>
                <div className="container p-5">
                    <div className="row">
                        <p className="text-muted text-center">We are open for any suggestion or just to have a chat!</p>
                        <hr />
                        {/* <p className="text-center mb-4">Let us know what you think! In order to provide better service,
                            please do not hesitate to give us your feedback. Thank you.</p><hr /> */}
                        <div className="col-lg-6">
                            <div className="d-flex justify-content-center align-items-center">
                                {/* <img src={Envelope} alt="Envelope" className="w-50 h-50 mt-5" /> */}
                                <div className="wrapper" style={{marginTop: "100px"}}>
                                    <div className="lid one"></div>
                                    <div className="lid two"></div>
                                    <div className="envelope"></div>
                                    <div className="letter">
                                        <p>Hey there,</p>
                                        <p>Lets have a chat!</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <form className="row g-3 needs-validation" noValidate onSubmit={submitForm} method="post">
                                <div className="contact-form mt-5">

                                    <div className="mb-4">
                                        <input type="text" id="username" name="username" className="form-control"
                                            placeholder="Your Name" required defaultValue={authenticated ? currentUser.user.name : ""} />
                                        <div className="invalid-feedback">
                                            Please tell us your name
                                        </div>
                                    </div>

                                    <div className="mb-4">
                                        <input type="email" id="email" name="email" className="form-control"
                                            placeholder="Your Email" required defaultValue={authenticated ? currentUser.user.email : ""} />
                                        <div className="invalid-feedback">
                                            Please provide a valid email
                                        </div>
                                    </div>

                                    <div className="mb-4">
                                        <textarea className="form-control" id="message" name="message" rows="5" required></textarea>
                                        <div className="invalid-feedback">
                                            Please provide a message
                                        </div>
                                    </div>

                                </div>

                                <div className="d-grid">
                                    <button type="submit" className="btn btn-primary mb-4">
                                        Send Message
                                    </button>
                                </div>
                            </form>
                            {
                                isSuccess && (
                                    <div className="alert alert-success mt-3" role="alert">
                                        <strong>Your Message has been sent!</strong>
                                    </div>
                                )}
                        </div>
                    </div>



                </div>
            </div>
        </section>
    );
}

export default ContactUs;