import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons';

function Quote() {
    return (
        <section className="quote">
                <blockquote className="blockquote text-center" data-aos="zoom-in-up" data-aos-duration="1000">
                    <p>
                    <FontAwesomeIcon icon={faQuoteLeft} className="fa-1x me-2 fs-2" style={{color:"#018abe"}}/>
                        <span className="font-italic fw-bold fs-1" style={{color:"#02457a"}}>
                            
                                Genius is one percent inspiration and ninety-nine percent perspiration.
                            
                        </span>
                    </p>
                    <footer className="blockquote-footer mt-3" style={{color:"#02457a"}}>Thomas Edison</footer>
                </blockquote>
        </section>
    );
}

export default Quote;