import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClone, faUser, faPenToSquare, faRightFromBracket, faPuzzlePiece } from '@fortawesome/free-solid-svg-icons';
import Container from 'react-bootstrap/Container';
import { Navbar, Nav, NavDropdown } from "react-bootstrap";

function HomeNavbar({ authenticated, currentUser, onLogout }) {
	const location = useLocation();
	const navigate = useNavigate();

	const [isNavCollapsed, setIsNavCollapsed] = useState(true);

	const handleNavCollapse = () => {
		console.log(isNavCollapsed);
		setIsNavCollapsed(!isNavCollapsed);
	};

	return (
		// <Navbar className="navbar navbar-expand-lg shadow" data-bs-theme="dark" style={{ backgroundColor: "#02457a" }}>
		// 	<Container>
		// 		<Navbar.Brand href="/">The Puzzle Factory</Navbar.Brand>
		// 		<Navbar.Toggle aria-controls="navbarResponsive" />
		// 		<Navbar.Collapse id="navbarResponsive">
		// 			<Nav className="ms-auto">
		// 				<Nav.Link href="/browse-quiz"><FontAwesomeIcon icon={faClone} style={{ marginRight: "8px" }} /> Quiz Topics</Nav.Link>
		// 				<NavDropdown title="Games" id="basic-nav-dropdown">
		// 					<NavDropdown.Item href="/games/tiktactoe">TikTacToe</NavDropdown.Item>
		// 				</NavDropdown>
		// 				<Nav.Link href="https://blog.thepuzzlefactory.co/"><FontAwesomeIcon icon={faPenToSquare} style={{ marginRight: "8px" }} />Articles</Nav.Link>
		// 				{
		// 					authenticated ? (
		// 						<React.Fragment>
		// 							<Nav.Link href="/profile"><FontAwesomeIcon icon={faClone} style={{ marginRight: "8px" }} /> Profile</Nav.Link>
		// 							<Nav.Link href="/" onClick={onLogout}><FontAwesomeIcon icon={faRightFromBracket} style={{ marginRight: "8px" }} /> Logout</Nav.Link>
		// 						</React.Fragment>
		// 					) : (
		// 						<Nav.Link href="/login"><FontAwesomeIcon icon={faUser} style={{ marginRight: "8px" }} /> Login</Nav.Link>
		// 					)
		// 				}
		// 			</Nav>
		// 		</Navbar.Collapse>
		// 	</Container>
		// </Navbar>


		// <nav className="navbar navbar-expand-lg navbar-dark bg-transparent fixed-top text-white shadow">
		<nav className="navbar navbar-expand-lg shadow" data-bs-theme="dark" style={{ backgroundColor: "#02457a" }}>
			<div className="container-fluid">
				<Link to="/" className="navbar-brand">The Puzzle Factory</Link>
				<button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive"
					aria-controls="navbarResponsive" aria-expanded={!isNavCollapsed ? true : false} aria-label="Toggle navigation"
					onClick={handleNavCollapse}>
					<span className="navbar-toggler-icon"></span>
				</button>
				<div className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`} id="navbarResponsive">
					<ul className="navbar-nav ms-auto">
						<li className="nav-item">
							<Link to="/browse-quiz" className="nav-link" onClick={handleNavCollapse}>
								<FontAwesomeIcon icon={faClone} style={{ marginRight: "8px" }} />Quiz Topics
								</Link>
						</li>
						{/* Challenges */}
						<li className="nav-item dropdown">
							<a className="nav-link dropdown-toggle" href="/games" role="button"
								data-bs-toggle="dropdown" aria-expanded="false">
								<FontAwesomeIcon icon={faPuzzlePiece} style={{ marginRight: "8px" }} />Games
							</a>
							<ul className="dropdown-menu" style={{ backgroundColor: "#02457a" }}>
								{/* <li><a className="dropdown-item" href="/games/chess">Chess</a></li> */}
								<Link to="/games/tiktactoe" className="nav-link" onClick={handleNavCollapse}>TikTacToe</Link>
							</ul>
						</li>
						<li className="nav-item">
							<a className="nav-link" href="https://blog.thepuzzlefactory.co/">
								<FontAwesomeIcon icon={faPenToSquare} style={{ marginRight: "8px" }} />Articles</a>
						</li>
						{
							authenticated ? (
								<React.Fragment>
									<li className="nav-item">
										<Link to="/profile" className="nav-link" onClick={handleNavCollapse}>
											<FontAwesomeIcon icon={faClone} style={{ marginRight: "8px" }} />Profile</Link>
									</li>
									<li className="nav-item">
										<a className="nav-link" onClick={onLogout} href="/">
											<FontAwesomeIcon icon={faRightFromBracket} style={{ marginRight: "8px" }} />
											Logout</a>
									</li>
								</React.Fragment>
							) : (
								<li className="nav-item">
									<a className="nav-link" href="/login">
										<FontAwesomeIcon icon={faUser} style={{ marginRight: "8px" }} />
										Login</a>
								</li>
							)
						}
					</ul>
				</div>
			</div>
		</nav>
	);
}

export default HomeNavbar;