
import React from "react";
import "./Tiktactoe.css";

function Box ({ value, onClick }) {
    const style = value === "X" ? "box x" : "box o";

    return (
        <button className={`box btn btn-light fs-1 fw-bold m-2 ${style}`}
        style={{width:"5rem", height:"5rem"}} 
        onClick={onClick}>{value}</button>
    );
}

export default Box;
