import React from "react";
import UserScoreTable from "./UserScoreTable";
import Designs from "../../assets/images/Designs_01.jpg"

function Leaderboard() {
    return (
        <section className="leaderboard">
            <div className="container p-0 overflow-hidden"
                style={{
                    marginTop: "80px", marginBottom: "20px",
                    boxShadow: "2px 2px 20px black", borderRadius: "20px"
                }}>
                <div className="text-center text-white broadcast p-5"
                    style={{
                        background: `url(${Designs})`, backgroundRepeat: "no-repeat",
                        backgroundSize: "cover", backgroundPosition: "center"
                    }}>
                    <h1>LEADER BOARD</h1>
                    {/* <div className="btn-group" role="group" aria-label="FilterType">
                        <button type="button" className="btn btn-primary">All time</button>
                        <button type="button" className="btn btn-primary">This Month</button>
                        <button type="button" className="btn btn-primary">This Week</button>
                    </div> */}
                </div>
                <div className="text-center text-white p-md-5 p-0">
                    <UserScoreTable />
                </div>
            </div>
        </section>
    );
}

export default Leaderboard;