import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons';
import IntroQuizImg from "../../assets/images/bluebulb.png"

function Intro() {
    return (
        <section className="intro">
            <div className="intro-img d-flex justify-content-center align-items-center" data-aos="zoom-in" data-aos-duration="1000">
                <img src={IntroQuizImg} alt="Intro LOGO" className="" style={{height:"250px", width: "300px"}}/>
            </div>
        </section>
    );
}

export default Intro;