import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { API_BASE_URL, ACCESS_TOKEN, USER_DETAILS } from "../../constants/config";
import { postReqJsonOptions } from "../../common/ApiUtils";

function Account({ currentUser }) {
    const navigate = useNavigate();
    const [hasAlert, setHasAlert] = useState(false);
    const [isNameUpdateSuccess, setIsNameUpdateSuccess] = useState(false);
    const [userNameVal, setUserNameVal] = useState(currentUser.user.username);
    const [confirmationText, setConfirmationText] = useState("");
    const [isDeleteButtonEnabled, setIsDeleteButtonEnabled] = useState(false);


    useEffect(() => {
        if (hasAlert) {
            const timer = setTimeout(() => {
                setHasAlert(false);
            }, 5000);

            return () => {
                clearTimeout(timer);
            };
        }
    }, [hasAlert]);

    useEffect(() => {
        if (isNameUpdateSuccess) {
            const timer = setTimeout(() => {
                setIsNameUpdateSuccess(false);
            }, 5000);

            return () => {
                clearTimeout(timer);
            };
        }
    }, [isNameUpdateSuccess]);

    const handleUsernameChange = (event) => {
        'use strict'

        event.preventDefault();
        var forms = document.querySelectorAll('.needs-validation')

        // Fetch all the forms we want Bootstrap validation to be applied. Loop over them and prevent submission
        Array.prototype.slice.call(forms)
            .forEach(function (form) {
                form.addEventListener('submit', function (event) {
                    if (!form.checkValidity()) {
                        event.preventDefault()
                        event.stopPropagation()
                    }
                    form.classList.add('was-validated')
                }, false)
            })


        const form = event.target;
        if (form.checkValidity()) {
            const formData = new FormData(form);
            const newUsername = formData.get('username');

            // Validate the new username based on your rules
            const usernamePattern = /^[A-Za-z][A-Za-z0-9]{2,19}$/; // 3-20 characters, letters or numbers, starting with a letter
            if (!usernamePattern.test(newUsername)) {
                setHasAlert(true);
                return;
            }

            currentUser.user.username = newUsername;

            fetch(`${API_BASE_URL}/user/changeUsername`, {
                body: JSON.stringify(currentUser.user),
                ...postReqJsonOptions()
            })
                .then(response => response.json())
                .then(json => {
                    form.reset();
                    setHasAlert(false);
                    setUserNameVal(newUsername);
                    setIsNameUpdateSuccess(true);
                    form.classList.remove('was-validated');
                })
                .catch(error => console.log('Error reported : ' + error.message));
        }

    };

    const handleDeleteProfile = () => {
        fetch(`${API_BASE_URL}/user/deleteProfile`, {
            ...postReqJsonOptions(),
            body: JSON.stringify(currentUser.user),
        })
            .then(response => {
                if (response.ok) {
                    localStorage.removeItem(ACCESS_TOKEN);
                    localStorage.removeItem(USER_DETAILS);
                    window.location.reload(false);
                    navigate(`/`);
                } else {
                    console.log('Error reported: ' + response.statusText);
                }
            })
            .catch(error => console.log('Error reported : ' + error.message));
    }

    const discard = (event) => {
        const form = event.target.closest('form'); // Find the closest form element
        if (form) {
            form.reset();
            setHasAlert(false);
            setIsNameUpdateSuccess(false);
            form.classList.remove('was-validated');
        }
    }

    const handleConfirmationTextChange = (event) => {
        const text = event.target.value;
        setConfirmationText(text);

        // Check if the confirmation text matches "ThePuzzleFactory"
        if (text === "ThePuzzleFactory") {
            setIsDeleteButtonEnabled(true);
        } else {
            setIsDeleteButtonEnabled(false);
        }
    };


    return (
        <div id="account-content" className="p-4 p-md-5 pt-5">
            <h2 className="mb-4">Your Username</h2>
            <form className="needs-validation" noValidate onSubmit={handleUsernameChange} method="post">
                <p className="text-muted">This is how users will see you on ThePuzzleFactory</p>
                <div className="d-flex flex-row">
                    <div className="me-2">
                        <input type="username" className="form-control" id="usernameInput" name="username"
                            aria-describedby="usernameHelp" placeholder={userNameVal} required />
                        <div className="invalid-feedback">
                            Provide a valid username
                        </div>
                    </div>
                    <div>
                        <button type="submit" className="btn btn-primary">
                            Update
                        </button>
                    </div>
                    {
                        hasAlert && (
                            <div>
                                <button type="btn" className="btn btn-primary ms-2" onClick={discard}>
                                    Discard
                                </button>
                            </div>
                        )
                    }
                </div>
                {
                    hasAlert && (
                        <div className="alert alert-info mt-3" role="alert">
                            <strong>Invalid Username!</strong>
                            Your username must be 3-20 characters long, contain letters or numbers,
                            starting with a letter and must not contain spaces, special characters, or emoji
                        </div>
                    )}
                {
                    isNameUpdateSuccess && (
                        <div className="alert alert-success mt-3" role="alert">
                            <strong>Username Updated!</strong>
                        </div>
                    )}
                <hr className="mt-5" />

                <h2 className="mb-4">Your Email Address</h2>
                <div className="mb-5">
                    <p className="text-muted">We will never share your email address or display it publicly.</p>
                    <input type="text" id="disabledTextInput" className="form-control"
                        placeholder={currentUser.user.email} disabled />
                </div>
            </form>

            <hr />

            <div className="user-delete">
                <h2 className="mb-4">Delete Account</h2>
                <p className="text-muted">
                    Deleting your account will result in the removal of all associated information,
                    including your profile page, earned points, and leaderboard positions.
                    Please be aware that all data will be permanently lost upon account deletion
                </p>
                <div className="d-flex flex-row mb-5">
                    <div>
                        <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                            Delete Account
                        </button>

                        <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false"
                            tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h1 className="modal-title fs-5" id="staticBackdropLabel">Confirm Account Deletion</h1>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                                            onClick={() => {
                                                setConfirmationText("");
                                            }}>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <p>Dear {currentUser.user.name}, we’re sorry to see you go!</p>
                                        <p>Please type "ThePuzzleFactory" in the box to confirm deletion</p>
                                        <div className="mb-3">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="confirmInput"
                                                value={confirmationText}
                                                onChange={handleConfirmationTextChange}
                                            />
                                        </div>

                                    </div>
                                    <div className="modal-footer d-flex justify-content-between">
                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal"
                                            onClick={() => {
                                                setConfirmationText("");
                                            }}>
                                            Close
                                        </button>
                                        <button type="button" className="btn btn-primary"
                                            disabled={!isDeleteButtonEnabled}
                                            onClick={handleDeleteProfile}>
                                            Delete
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Account;