import React from "react";
import PageError from "../assets/images/404.png";

function PageNotFound() {
    return (
        <section>
            <div className="broadcast">
                <div className="p-5 d-flex flex-column align-items-center justify-content-center text-white">
                    <img src={PageError} className="mt-5 mb-2"
                    style={{ height: "200px"}}
                        alt="Page Not Found" />
                    <h1 className="fw-bold fs-1">Page Not Found</h1>
                    <p className="text-center mb-5">
                        The page you are looking for might have been removed or doesn't exist.<br/>
                        Please check the URL or navigate to another page.
                    </p>
                </div>
            </div>
        </section>
    );
}

export default PageNotFound;