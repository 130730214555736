import React, { useState, useEffect } from "react";
import { API_BASE_URL } from "../../constants/config";
import { postReqJsonOptions } from "../../common/ApiUtils";
import { ACCESS_TOKEN } from "../../constants/config";

function UploadQuestion() {
    const [selectedChoice, setSelectedChoice] = useState(null);
    const [checkboxErrors, setCheckboxErrors] = useState([]);
    const [isFormValidated, setIsFormValidated] = useState(false);
    const [quizzes, setQuizzes] = useState([]);
    const [selectedQuiz, setSelectedQuiz] = useState("");
    const errors = [];

    useEffect(() => {
        fetchQuizzes().then(data => {
            setQuizzes(data);
        });
    }, []);

    const fetchQuizzes = async () => {
        try {
            const response = await fetch(`${API_BASE_URL}/quiz/getAllQuizzes`);
            const quizzes = await response.json();
            return quizzes;
        } catch (error) {
            console.error('Error fetching quizzes:', error);
            return [];
        }
    };

    const handleSelectedQuiz = (event) => {
        setSelectedQuiz(event.target.value);
    };

    const handleCheckboxChange = (index) => {
        setSelectedChoice(index === selectedChoice ? null : index);
    };

    const handleQuestionFormChange = (event) => {
        'use strict'

        event.preventDefault();
        var forms = document.querySelectorAll('.needs-validation')

        // Fetch all the forms we want Bootstrap validation to be applied. Loop over them and prevent submission
        Array.prototype.slice.call(forms)
            .forEach(function (form) {
                form.addEventListener('submit', function (event) {
                    if (!form.checkValidity()) {
                        event.preventDefault()
                        event.stopPropagation()
                    }
                    form.classList.add('was-validated')
                }, false)
            })


        const form = event.target;
        if (form.checkValidity() && isCorrectOptionSelected()) {
            const formData = new FormData(event.target);
            const choices = [formData.get('choice1'), formData.get('choice2'), formData.get('choice3'), formData.get('choice4')];
            const answerIndex = selectedChoice - 1;
            setIsFormValidated(true);

            const requestBody = {
                problem: formData.get('problem'),
                type: formData.get('type'),
                choices: choices,
                correctOption: choices[answerIndex],
                quizId: selectedQuiz
            };

            fetch(`${API_BASE_URL}/qn/addQuestion`, {
                ...postReqJsonOptions(),
                body: JSON.stringify(requestBody)
            })
                .then(response => response.json())
                .then(json => console.log(json))
                .catch(error => console.log('Error reported : ' + error.message));
        }

        function isCorrectOptionSelected() {
            if (selectedChoice === null) {
                errors.push("At least one choice must be selected.");
            }
            setCheckboxErrors(errors);
            return errors.length > 0 ? false : true;
        }
    };

    return (
        <form className="row g-3 needs-validation" noValidate onSubmit={handleQuestionFormChange} method="post">
            <div className="add-question mt-5">
                <h3 className="text-center mb-4"><strong>Add Question</strong></h3>

                <div className="mb-4">
                    <input type="text" id="problem" name="problem" className="form-control" placeholder="Question" required />
                    <div className="invalid-feedback">
                        Please add a question
                    </div>
                </div>

                <div className="mb-4">
                    <select className="form-select mb-4 text-muted" defaultValue="" name="type" aria-label="Type select" id="selectType" required>
                        <option value="">Select Question Type</option>
                        <option value="MCQ">MCQ</option>
                        <option disabled value="TrueOrFalse">TrueOrFalse</option>
                        <option disabled value="MatchPairs">MatchPairs</option>
                    </select>
                    <div className="invalid-feedback">
                        Please select a valid Type
                    </div>
                </div>

                <div className="mb-4">
                    <select className="form-select mb-4 text-muted has-validation"
                        aria-label="Type select" name="quizSelect" value={selectedQuiz} onChange={handleSelectedQuiz} required>
                        <option value="">Select Quiz</option>
                        {
                            quizzes.map(
                                quiz => (
                                    <option key={quiz.id} value={quiz.id}>{quiz.title}</option>
                                ))
                        }
                    </select>
                    <div className="invalid-feedback">Please select a quiz.</div>
                </div>

                <div className="row mb-4">
                    <div className="col">
                        <div className="form-check form-check-reverse d-flex align-items-center">
                            <input type="text" id="choice1" name="choice1" className="form-control me-2" placeholder="Choice 1" required />
                            <input className="form-check-input" type="checkbox" value=""
                                id="flexCheckDefault1" checked={selectedChoice === 1} onChange={() => handleCheckboxChange(1)} />
                        </div>
                    </div>

                    <div className="col">
                        <div className="form-check form-check-reverse d-flex align-items-center">
                            <input type="text" id="choice2" name="choice2" className="form-control me-2" placeholder="Choice 2" required />
                            <input className="form-check-input" type="checkbox" value=""
                                id="flexCheckDefault2" checked={selectedChoice === 2} onChange={() => handleCheckboxChange(2)} />
                        </div>
                    </div>
                </div>

                <div className="row mb-4">
                    <div className="col">
                        <div className="form-check form-check-reverse d-flex align-items-center">
                            <input type="text" id="choice3" name="choice3" className="form-control me-2" placeholder="Choice 3" required />
                            <input className="form-check-input" type="checkbox" value=""
                                id="flexCheckDefault3" checked={selectedChoice === 3} onChange={() => handleCheckboxChange(3)} />
                        </div>
                    </div>

                    <div className="col">
                        <div className="form-check form-check-reverse d-flex align-items-center">
                            <input type="text" id="choice4" name="choice4" className="form-control me-2" placeholder="Choice 4" required />
                            <input className="form-check-input" type="checkbox" value=""
                                id="flexCheckDefault4" checked={selectedChoice === 4} onChange={() => handleCheckboxChange(4)} />
                        </div>
                    </div>

                </div>
            </div>

            {checkboxErrors.length > 0 && (
                <div className="alert alert-danger">
                    <ul>
                        {checkboxErrors.map((error, index) => (
                            <li key={index}>{error}</li>
                        ))}
                    </ul>
                </div>
            )}

            <div className="d-grid">
                <button type="submit" className="btn btn-primary mb-4">
                    Submit
                </button>
            </div>
        </form>
    );
}

export default UploadQuestion;