import React from "react";
import Box from "./Box";

function Board({ board, onClick }) {
  return (
    <div className="board d-grid justify-content-center" style={{ gridTemplateColumns: "repeat(3, 6rem)" }}>
      {
        board.map((value, idx) => {
          return <Box value={value} onClick={() => value === null && onClick(idx)} />;
        })
      }
    </div>
  );
}

export default Board;