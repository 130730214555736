import React from "react";
import Logo from "../assets/images/logo small.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faYoutube, faFacebook, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';


function Footer() {
	return (
		<footer className="text-center text-lg-start text-white broadcast opacity-75" style={{ backgroundColor: "#ebf4fa"}}>
			<div className="container p-4 pb-0 text-center text-md-start">
				<div className="row mt-3">
					{/* <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
							<h6 className="text-uppercase mb-4 fw-bold">The Puzzle Factory</h6>
							<p>
								Lorem ipsum dolor sit amet, consectetur adipisicing
								elit.
							</p>
						</div> */}
					<div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
						<img src={Logo} alt="Logo" height="70" className="img" />
					</div>

					<div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
						<h6 className="text-uppercase mb-4 fw-bold">About Us</h6>
						{/* <p>
							<a href="/" className="text-white">The Team</a>
						</p> */}
						<p>
							<a href="/legal/mission-vision" className="text-white">Mission & Vision</a>
						</p>
						<p>
							<a href="/legal/privacy-policy" className="text-white">Privacy Policy</a>
						</p>
						<p>
							<a href="/legal/terms-of-service" className="text-white">Terms of Service</a>
						</p>
					</div>

					<div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
						<h6 className="text-uppercase mb-4 fw-bold">Company</h6>
						<p>
							<a href="/company/faq" className="text-white">FAQs</a>
						</p>
						<p>
							<a href="/company/testimonials" className="text-white">Testimonials</a>
						</p>
						<p>
							<a href="/connect/contactus" className="text-white">Contact Us</a>
						</p>
						{/* <p>
							<a href="/company/help" className="text-white">Help</a>
						</p> */}
					</div>

					<div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
						<h6 className="text-uppercase mb-4 fw-bold">Contact</h6>
						<p><FontAwesomeIcon icon={faHouse} style={{ marginRight: "8px" }} /> Auckland, New Zealand</p>
						<p><FontAwesomeIcon icon={faEnvelope} style={{ marginRight: "8px" }} /> info@thepuzzlefactory.co</p>
						{/* <p><FontAwesomeIcon icon={faPhone} style={{ marginRight: "8px" }} /> + 64 210 82 83 907</p> */}
					</div>
				</div>
			</div>


			<ul className="list-unstyled list-inline social text-center text-white">
				<li className="list-inline-item"><a href="/" className="btn btn-link btn-lg text-white m-1">
					<FontAwesomeIcon icon={faFacebook} /></a></li>
				<li className="list-inline-item"><a href="/" className="btn btn-link btn-lg text-white m-1">
					<FontAwesomeIcon icon={faTwitter} /></a></li>
				<li className="list-inline-item"><a href="https://instagram.com/thepuzzlefactory.co" className="btn btn-link btn-lg text-white m-1">
					<FontAwesomeIcon icon={faInstagram} /></a></li>
				<li className="list-inline-item"><a href="https://youtube.com/@ThePuzzleFactory" className="btn btn-link btn-lg text-white m-1">
					<FontAwesomeIcon icon={faYoutube} /></a></li>
			</ul>

			<div className="text-center p-3" style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}>
				© 2023 Copyright:
				<a className="text-white" href="https://thepuzzlefactory.co/">ThePuzzleFactory.Co</a>
			</div>
		</footer>
	);
}

export default Footer;