import React from "react";

function PrivacyPolicy() {
    return (
        <section className="privacypolicy">
            <div className="container p-0 overflow-hidden"
                style={{
                    marginTop: "80px", marginBottom: "20px",
                    boxShadow: "2px 2px 20px black", borderRadius: "20px"
                }}>
                <div className="text-center text-white broadcast p-5">
                    <h1>Privacy Policy</h1>
                </div>
                <div className="p-5">
                    <p className="text-info fst-italic">Effective Date: 21 September 2023</p>
                    <p>
                        This Privacy Policy describes how The Puzzle Factory collects,
                        uses, and discloses personal information when you use our 
                        web application <a href="https://www.thepuzzlefactory.co/" className="fw-bold">thepuzzlefactory.co</a>
                    </p>

                    <h2>1. Information We Collect</h2>
                    <h3>1.1. Information You Provide:</h3>
                    <p>
                        When you use our Service, you may provide us with personal information, such as your name,
                        email address, and any other information you choose to provide when interacting with the Service.
                    </p>
                    <h3>1.2. Automatically Collected Information:</h3>
                    <p>
                        We may automatically collect certain information about your device and how you interact
                        with our Service. This may include your IP address, browser type, operating system, and usage data.
                    </p>
                    <h2>2. How We Use Your Information</h2>
                    <h3>2.1. We may use the information we collect for various purposes, including:</h3>
                    <p>
                        <ul>
                            <li>To provide and maintain the Service.</li>
                            <li>To improve, personalize, and expand our Service.</li>
                            <li>To send you updates, newsletters, and promotional materials.</li>
                            <li>To respond to your requests, comments, or questions.</li>
                            <li>To monitor and analyze usage patterns and trends.</li>
                        </ul>
                    </p>
                    <h2>3. Disclosure of Your Information</h2>
                    <h3>3.1. We may share your personal information with:</h3>
                    <p>
                        <ul>
                            <li>Service providers who help us operate and maintain the Service.</li>
                            <li>Law enforcement or government authorities in response to legal requests or as required by applicable law.</li>
                            <li>Third parties in connection with a merger, acquisition, or sale of assets.</li>
                        </ul>
                    </p>
                    <h2>4. Cookies and Tracking Technologies</h2>
                    <p>
                        4.1. We may use cookies and similar tracking technologies to collect and track information
                        about your use of the Service. You can control the use of cookies through your browser settings.
                    </p>
                    <h2>5. Third-Party Links</h2>
                    <p>
                        5.1. Our Service may contain links to third-party websites or services.
                        We are not responsible for the practices of these third parties, and their privacy policies
                        will govern the collection and use of your information.
                    </p>
                    <h2>6. Security</h2>
                    <p>
                        6.1. We take reasonable steps to protect your personal information. However,
                        no method of transmission over the internet or electronic storage is entirely secure,
                        and we cannot guarantee the absolute security of your information.
                    </p>
                    <h2>7. Your Choices</h2>
                    <p>
                        7.1. You can review and update your personal information by contacting us.
                        You can also opt out of receiving promotional emails from us by following the instructions in those emails.
                    </p>
                    <h2>9. Changes to this Privacy Policy</h2>
                    <p>
                        9.1. We may update this Privacy Policy from time to time. We will notify you of any
                        changes by posting the new Privacy Policy on this page, along with the effective date.
                    </p>
                    <h2>10. Contact Us</h2>
                    <p>
                        10.1. If you have any questions or concerns about this Privacy Policy,
                        please contact us at info@thepuzzlefactory.co.
                    </p>
                </div>
            </div>
        </section>

    );
}

export default PrivacyPolicy;