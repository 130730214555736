import React, { useEffect, useState } from "react";
import { TPF } from "../constants/config";

function Faq() {
    const [accordionText, setAccordionText] = useState("EXPAND ALL");

    useEffect(() => {
        // Function to toggle all accordion items
        const toggleAllAccordions = () => {
            const accordionItems = document.querySelectorAll(".accordion-collapse");
            accordionItems.forEach((item) => {
                const isCollapsed = item.classList.contains("collapse");
                if (isCollapsed) {
                    item.classList.remove("collapse");
                } else {
                    item.classList.add("collapse");
                }
            });
        };

        // Add click event handler to the global button
        const globalAccordionButton = document.getElementById("globalAccordionButton");
        if (globalAccordionButton) {
            globalAccordionButton.addEventListener("click", toggleAllAccordions);
        }

        // Clean up the event listener when the component unmounts
        return () => {
            if (globalAccordionButton) {
                globalAccordionButton.removeEventListener("click", toggleAllAccordions);
            }
        };
    }, []);

    return (
        <section className="faq">
            <div className="container p-0 overflow-hidden"
                style={{
                    marginTop: "80px", marginBottom: "20px",
                    boxShadow: "2px 2px 20px black", borderRadius: "20px"
                }}>
                <div className="text-center text-white broadcast p-5">
                    <h1>Frequently Asked Questions</h1>
                </div>
                <div className="p-5">
                    {/* <div className="d-flex">
                        <button id="globalAccordionButton" className="btn btn-primary mb-2 me-2" type="button" data-bs-toggle="collapse"
                            data-bs-target=".accordion-collapse" aria-expanded="false" aria-controls="collapseExample">
                            {accordionText}
                        </button>
                    </div> */}
                    <div className="accordion" id="accordionExample">
                        <div className="accordion-item">
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                    How do I contact {TPF} Support from the help center?
                                </button>
                            </h2>
                            <div id="collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    You can contact {TPF} Support using
                                    the <a href="/connect/contactus">Contact Form</a>.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    Does {TPF} offer phone support?
                                </button>
                            </h2>
                            <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    {TPF} offers support primarily over email and doesn't currently offer phone support.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    When will I get a response from {TPF} Support?
                                </button>
                            </h2>
                            <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    {TPF} typically responds within 1-2 business days.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                    What if I don't want my score to be published in Leaderboard?
                                </button>
                            </h2>
                            <div id="collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    You can turn off Score Visibility by following these steps:
                                    <ul>
                                        <li>
                                            Sign in to your account
                                        </li>
                                        <li>
                                            Go to your Profile from the Navbar
                                        </li>
                                        <li>
                                            In Settings Section, under Preferences, in the Visibility section,
                                            turn off "Show Score on Leaderboard"
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Faq;