import { API_BASE_URL, ACCESS_TOKEN, USER_DETAILS } from "../constants/config";

// GET requestOptions object with headers
export function getRequestOptions() {
  const requestOptions = {
    method: "GET",
    headers: {
      "Authorization": "Bearer " + localStorage.getItem(ACCESS_TOKEN),
      "Content-Type": "application/json",
    },
  };

  return requestOptions;
}

// POST requestOptions object with headers
export function postRequestOptions() {
  const requestOptions = {
    method: "POST",
    headers: {
      "Authorization": "Bearer " + localStorage.getItem(ACCESS_TOKEN),
    },
  };

  return requestOptions;
}

// POST requestOptions object with headers
export function postReqJsonOptions() {
  const requestOptions = {
    method: "POST",
    headers: {
      "Authorization": "Bearer " + localStorage.getItem(ACCESS_TOKEN),
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
  };

  return requestOptions;
}

export function getCurrentUser() {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  const requestOptions = getRequestOptions();

  return fetch(API_BASE_URL + "/profile", requestOptions)
    .then(response => {
      if (!response.ok) {
        console.error("Response not ok. Status:", response.status);
        return response.text().then(text => {
          console.error("Response text:", text);
          return Promise.reject(text);
        });
      }
      return response.json();
    })
    .then(data => {
      localStorage.setItem(USER_DETAILS, JSON.stringify(data));
      return data;
    })
    .catch(error => {
      console.error("Error fetching user data:", error);
      throw error;
    });

}