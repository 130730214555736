import React, { useState } from "react";
import Hexagon from "../../assets/images/vecteezy_geometric-hexagon.jpg";

function TikTacToeScreen1() {
    return (
        <section className="my-5">

            <div className="container d-block text-center p-2"
                style={{
                    boxShadow: "0px 0px 8px #888888",
                    background: `url(${Hexagon})`, backgroundRepeat: "no-repeat",
                    backgroundSize: "cover", backgroundPosition: "center"
                }}>
                <div className="row p-5">
                    <h1 className="fw-bold fs-1">Tic Tac Toe</h1>
                </div>
                <div className="p-5 d-grid gap-2 col-lg-6 mx-auto">
                    {/* <a href="/games/tiktactoe-singleplayer" className="btn btn-success btn-lg text-white m-1">
                        Single Player
                    </a> */}
                    <a href="/games/tiktactoe-twoplayer" className="btn btn-success btn-lg text-white m-1">
                        Two Player - Same Screen
                    </a>
                    {/* <a href="/games/tiktactoe-singleplayer" className="btn btn-success btn-lg text-white m-1">
                        Two Player - Search Online Players
                    </a> */}
                </div>
            </div>

        </section>
    );
}

export default TikTacToeScreen1;