import React, { useState, useEffect } from "react";
import QuizCard from "../quiz-inc/Quiz-Card";
import { API_BASE_URL } from "../../constants/config";
import Scoreboard from "../quiz-inc/Scoreboard";
import SocialWidgets from "../blog-inc/Social-Widgets";
import AdsSidebar from "../quiz-inc/Ads-Sidebar";

function BrowseQuiz() {
    const [options] = useState(["Geography & Travel", "Health & Medicine", "Lifestyles & Social Issues", "Literature",
        "Politics, Law & Government", "Science", "Sports", "Technology", "History", "Space"]);
    const [currentSelection, setCurrentSelection] = useState("All");
    const [filteredCards, setFilteredCards] = useState([]);
    const [quizData, setQuizData] = useState([]);
    const [loading, setLoading] = useState(true);
    // const [loadCards, setLoadCards] = useState(false);

    useEffect(() => {
        fetch(`${API_BASE_URL}/quiz/getAllQuizzes`)
            .then(response => response.json())
            .then(data => {
                setQuizData(data);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching quiz data:', error);
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        const select = document.getElementById("browseCategory");
        options.forEach((opt) => {
            const existingOption = Array.from(select.childNodes).find(
                (node) => node.value === opt
            );

            if (!existingOption) {
                const el = document.createElement("option");
                el.textContent = opt;
                el.value = opt;
                select.appendChild(el);
            }
        });
    }, []);

    useEffect(() => {

        if (currentSelection === "All") {
            setFilteredCards(quizData);
        } else {
            setFilteredCards(quizData.filter(data => data.category === currentSelection));
        }
    }, [currentSelection, quizData]);

    const handleCategoryChange = (event) => {
        setCurrentSelection(event.target.value);
    };

    return (
        <React.Fragment>
            <main className="main" style={{ marginTop: "80px" }}>
                <div className="container">
                    <div className="md-page-wrapper">
                        <div id="content" className="md-content mb-5">
                            <div className="container pt-20 pt-sm-20 quiz-browse-heading">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="/"
                                            style={{ textDecoration: "none", color: "black" }}>Home</a></li>
                                        <li className="breadcrumb-item"><a href="/browse-quiz"
                                            style={{ textDecoration: "none", color: "black" }}>Quiz</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">{currentSelection}</li>
                                    </ol>
                                </nav>

                                <div className="row align-items-center">
                                    <div className="col-lg-9 justify-content-left">
                                        <h1 className="display-1 quiz-h1">Quiz</h1>
                                    </div>
                                    <div className="col-lg-3">
                                        <select
                                            className="quiz-category-select form-select form-select-sm"
                                            name="Browse Quiz Categories" id="browseCategory"
                                            value={currentSelection} onChange={handleCategoryChange}>
                                            <option value="All">See All Quiz Categories</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <section className="content">
                            <div className="container">
                                <div className="row">
                                    <div className="col-9">
                                        {
                                            loading ? (
                                                <p>Loading quizzes...</p>
                                            ) : (
                                                filteredCards.length > 0 ? (
                                                    <div className="row g-4 row-cols-1 row-cols-lg-3">
                                                        {
                                                            filteredCards.map(
                                                                data => (
                                                                    <div className="col" key={data.id}>
                                                                        <QuizCard
                                                                            id={data.id}
                                                                            image="https://t4.ftcdn.net/jpg/00/67/24/59/360_F_67245954_ejVa8C414CwJ9X0UadIFu1QEUjeLuFnO.jpg"
                                                                            title={data.title}
                                                                            description={data.description}
                                                                        />
                                                                    </div>
                                                                ))
                                                        }
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <p className="">No quizzes found in this section.</p>
                                                    </div>
                                                )




                                            )}

                                    </div>
                                    <div className="col-lg-3 mt-5 mt-lg-0">
                                        <SocialWidgets />
                                        <Scoreboard />
                                        <AdsSidebar />
                                    </div>
                                </div>
                            </div>
                        </section>

                        <hr className="my-4" />

                        <p className="text-center mb-4 fst-italic">
                            <strong>* Affiliate Disclaimer</strong>: This page may contain affiliate links.
                            This means that if you make a purchase through these links, I may earn a commission at no extra cost to you.
                            Your support goes a long way in helping us continue our journey at ThePuzzleFactory!
                        </p>
                    </div>
                </div>
            </main>
        </React.Fragment>
    );
}

export default BrowseQuiz;