import React from "react";

function Chess() {
    return (
        <section className="chess-game">

        </section>
    )
}

export default Chess;