import React, { useState, useEffect } from "react";
import IntroQuizImg from "../../assets/images/pexels-the-happiest-face-866351.jpg"
import { API_BASE_URL } from "../../constants/config";
import { useNavigate } from 'react-router-dom';
import QuizCard from "../quiz-inc/Quiz-Card";
import { useMediaQuery } from 'react-responsive';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

function HomeQuiz() {
    const [quizData, setQuizData] = useState([]);
    const isDesktop = useMediaQuery({ query: "(min-width: 900px)" });
    const isIpad = useMediaQuery({ query: "(min-width: 600px) and (max-width: 899px)" });
    const isMobile = useMediaQuery({ query: "(max-width: 599px)" });
    const [chunkSize, setChunkSize] = useState(1);

    useEffect(() => {
        if (isIpad) {
            setChunkSize(4);
        } else if (isDesktop) {
            setChunkSize(4);
        } else {
            setChunkSize(1);
        }
    }, [isIpad, isDesktop]);

    const navigate = useNavigate();

    const handleExplore = () => {
        navigate(`/browse-quiz`);
    };

    useEffect(() => {
        fetch(`${API_BASE_URL}/quiz/getAllQuizzes`)
            .then(response => response.json())
            .then(data => setQuizData(data))
            .catch(error => console.error('Error fetching quiz data:', error));
    }, []);

    // Split quizData into chunks
    const cardChunks = [];
    for (let i = 0; i < quizData.length; i += chunkSize) {
        cardChunks.push(quizData.slice(i, i + chunkSize));
    }

    return (
        <section className="mb-5" >
            <div className="intro-quiz">
                <div className="explore-quiz-title text-white text-center p-5" style={{
                    background: `url(${IntroQuizImg})`, backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center"
                }}>
                    <h2 className="fw-bold fs-1">YOUR QUIZ ADVENTURE AWAITS</h2>
                    <p className="fs-4 mt-3">Dive into a variety of quizzes and test your knowledge.</p>

                    <div className="px-5 mt-3">
                        <div className="d-grid gap-3 d-flex mx-5 justify-content-center">
                            {/* <button className="btn btn-outline-light flex-fill"
                                type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                                Previous
                            </button>
                            <button className="btn btn-outline-light flex-fill"
                                type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                                Next
                            </button> */}
                            <button className="btn btn-outline-light rounded-circle"
                                type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                                <FontAwesomeIcon icon={faChevronLeft} />
                            </button>
                            <button className="btn btn-outline-light rounded-circle"
                                type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                                <FontAwesomeIcon icon={faChevronRight} />
                            </button>
                        </div>
                    </div>


                    <div id="carouselExampleIndicators" className="carousel slide px-5 mt-4" data-ride="carousel">
                        <div className="carousel-inner">
                            {
                                cardChunks.map((chunk, index) => (
                                    <div className={`carousel-item ${index === 0 ? 'active' : ''}`} key={`carousel-item-${index}`}>
                                        <div className="row">
                                            {
                                                chunk.map(data => (
                                                    <div className="col-md-3" key={data.id}>
                                                        <QuizCard
                                                            id={data.id}
                                                            image="https://t4.ftcdn.net/jpg/00/67/24/59/360_F_67245954_ejVa8C414CwJ9X0UadIFu1QEUjeLuFnO.jpg"
                                                            title={data.title}
                                                            description={data.description}
                                                        />
                                                    </div>
                                                ))}
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <button className="btn btn-outline-light mt-5" onClick={handleExplore}>Explore More</button>
                </div>
            </div>
        </section>
    );
}

export default HomeQuiz;