import React from "react";
import Slider1 from '../../assets/images/slider-1.jpg';
import Slider2 from '../../assets/images/slider-2.jpg';
import Slider3 from '../../assets/images/slider-3.jpg';

function Slider() {
	return (
		<div id="introCarousel" className="carousel slide carousel-fade" data-bs-ride="carousel" style={{position: "relative"}}>
			<div className="carousel-indicators">
				<button type="button" data-bs-target="#introCarousel" data-bs-slide-to="0" className="active"
					aria-current="true" aria-label="Slide 1"></button>
				<button type="button" data-bs-target="#introCarousel" data-bs-slide-to="1"
					aria-label="Slide 2"></button>
				<button type="button" data-bs-target="#introCarousel" data-bs-slide-to="2"
					aria-label="Slide 3"></button>
			</div>
			<div className="carousel-inner">
				<div className="carousel-item active slider-carousel" style={{ backgroundImage: `url(${Slider1})` }}>
					<div className="carousel-caption">
						<h5>Unleash Your Curiosity</h5>
						<p>Explore a world of captivating quizzes and challenge your mind with intriguing questions.</p>
					</div>
				</div>

				<div className="carousel-item slider-carousel" style={{ backgroundImage: `url(${Slider2})` }}>
					<div className="carousel-caption">
						<h5>Knowledge Playground</h5>
						<p>where learning becomes an adventure!</p>
					</div>
				</div>
				<div className="carousel-item slider-carousel" style={{ backgroundImage: `url(${Slider3})` }}>
					<div className="carousel-caption">
						<h5>Quiz Quest for All Ages</h5>
						<p>Join us on a quiz quest that's suitable for all ages. </p>
					</div>
				</div>
			</div>
			<button className="carousel-control-prev" type="button" data-bs-target="#introCarousel" data-bs-slide="prev">
				<span className="carousel-control-prev-icon" aria-hidden="true"></span>
				<span className="visually-hidden">Previous</span>
			</button>
			<button className="carousel-control-next" type="button" data-bs-target="#introCarousel" data-bs-slide="next">
				<span className="carousel-control-next-icon" aria-hidden="true"></span>
				<span className="visually-hidden">Next</span>
			</button>
			{/* <div className="white-circle">
			</div> */}
		</div>
	);
}

export default Slider;