import React from "react";

function AdsSidebar() {
    return (
        <section className="ads-sidebar">
            <h3 className="mt-4 fw-bold" style={{ color: "#007cb9" }}>Sponsored Products</h3>
            <div className="ads mt-3 text-center">
                <iframe sandbox="allow-popups allow-scripts allow-modals allow-forms allow-same-origin"
                    style={{ width: "120px", height: "240px", marginWidth: "0", marginHeight: "0", scrolling: "no", frameBorder: "0" }}
                    src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=thepuzzlefact-20&language=en_US&marketplace=amazon&region=US&placement=164845044X&asins=164845044X&linkId=9aff8e268afb7236559c199472c6fb35&show_border=true&link_opens_in_new_window=true">
                </iframe>
            </div>
            <div className="ads mt-3 text-center">
                <iframe sandbox="allow-popups allow-scripts allow-modals allow-forms allow-same-origin" className="me-2"
                    style={{ width: "120px", height: "240px", marginWidth: "0", marginHeight: "0", scrolling: "no", frameBorder: "0" }}
                    src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=thepuzzlefact-20&language=en_US&marketplace=amazon&region=US&placement=B08LLGC3SJ&asins=B08LLGC3SJ&linkId=8dd12baef15a7dbf3ed1241423523944&show_border=true&link_opens_in_new_window=true">
                </iframe>
            </div>
            <div className="ads mt-3 text-center">
                <iframe sandbox="allow-popups allow-scripts allow-modals allow-forms allow-same-origin"
                    style={{ width: "120px", height: "240px", marginWidth: "0", marginHeight: "0", scrolling: "no", frameBorder: "0" }}
                    src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=thepuzzlefact-20&language=en_US&marketplace=amazon&region=US&placement=1426310188&asins=1426310188&linkId=a28560bb98ecc790f6de9ea9de7ef6fa&show_border=true&link_opens_in_new_window=true"></iframe>
            </div>
        </section>
    );
}

export default AdsSidebar;