import React from "react";
import ScoreBoardMinimal from "./ScoreBoardMinimal";

function Scoreboard() {
    return (
        <section className="scoreboard">
            <h3 className="mt-4 fw-bold" style={{ color: "#007cb9" }}>Score Board</h3>
            <ScoreBoardMinimal/>

            <div className="text-center mt-3">
                <a href="/leaderboard" className="fw-bold text-body text-info-hover">
                    View Leaderboard
                </a>
            </div>
        </section>
    );
}

export default Scoreboard;